<template>
    <ul class="p-4">
        <li v-for="node in tree" :key="node.id">
            <div>
                <div class="relative">
                    <a class="block text-white flex-1 py-2" :class="node.classes" :href="node.url">{{ node.title }}</a>

                    <button
                        v-if="node.children.length"
                        class="block absolute right-0 align-y mr-6 w-6 h-6 text-white appearance-none bg-transparent border-0 focus:outline-none"
                        type="button"
                        @click="active[node.id] = !active[node.id]"
                    >
                        <span class="block transition" :class="{'rotate-180': active[node.id]}">
                            <slot />
                        </span>
                    </button>
                </div>
            </div>

            <collapse-transition v-show="node.children.length">
                <ul v-show="active[node.id]">
                    <li v-for="subnode in node.children" :key="subnode.id" class="panel__subitem">
                        <a class="block text-white py-2 pl-10" :class="subnode.classes" :href="subnode.url">{{ subnode.title }}</a>
                    </li>
                </ul>
            </collapse-transition>
        </li>
    </ul>
</template>

<script>
    export default {
        name: 'Navigation',
        props: {
            tree: {
                type: Array,
                required: true,
            },
        },
        data() {
            const active = {};
            for (const node of this.tree) {
                active[node.id] = false;
            }

            return {
                active,
            };
        },
    };
</script>
