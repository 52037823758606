import axios from '../../utils/axios';

const state = () => ({
    loading: false,
    email: null,
    items: [],
    orderAdjustments: [],
    countries: [],
    shippingAddress: null,
    billingAddress: null,
    shippingMethodHandle: null,
    shippingMethods: [],
    shippingMethod: null,
    itemSubtotal: null,
    totalShippingCost: null,
    totalDiscount: null,
    totalPrice: null,
    stripe: null,
    paypal: null,
});

const mutations = {
    setLoading(state, loading) {
        state.loading = loading;
    },

    setCart(state, cart) {
        for (const key in cart) {
            if (Object.prototype.hasOwnProperty.call(cart, key) && typeof state[key] !== 'undefined') {
                state[key] = cart[key];
            }
        }
    },
};

const actions = {
    async addToCart({ commit, dispatch }, payload) {
        let id = payload;
        let quantity;
        let options = {};

        if (typeof payload !== 'number' && typeof payload !== 'string') {
            ({ id, options, quantity } = payload);
        }

        try {
            await axios.post('/actions/commerce/cart/update-cart', {
                qty: quantity || 1,
                options,
                purchasableId: id,
            });

            await dispatch('loadCart');

            return true;
        } catch (error) {
            console.error(error);
        }

        return false;
    },
    async removeFromCart({ commit, dispatch, state }, variantId) {
        try {
            const lineItem = state.items.find(item => `${item.variantId}` === `${variantId}`);
            if (!lineItem) {
                return false;
            }

            await axios.post('/actions/commerce/cart/update-cart', {
                lineItems: {
                    [lineItem.id]: {
                        remove: true,
                    },
                },
            });

            await dispatch('loadCart');

            return true;
        } catch (error) {
            console.error(error);
        }

        return false;
    },
    async loadCart({ commit }) {
        try {
            commit('setLoading', true);

            const { data } = await axios.get('/shop/cart.json');

            commit('setCart', data);

            commit('setLoading', false);

            return data;
        } catch (error) {
            console.error(error);
        }

        return false;
    },

    async deleteAddress({ commit, dispatch, state }, addressId) {
        try {
            await axios.post('/actions/users/delete-address', {
                addressId,
            });

            await dispatch('loadCart');

            return true;
        } catch (error) {
            console.error(error);
        }

        return false;
    },

    async updateCart({ commit, dispatch, state }, data) {
        try {
            await axios.post('/actions/commerce/cart/update-cart', data);
            return await dispatch('loadCart');
        } catch (error) {
            console.error(error);
        }

        return false;
    },
};

const getters = {
    hasItems(state) {
        return state.items.length > 0;
    },
    itemCount(state) {
        return state.items.length;
    },
    isInCart(state) {
        return variantId => !!state.items.find(item => `${item.variantId}` === `${variantId}`);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
