import Axios from 'axios';
import { stringify } from 'qs';

export default Axios.create({
    withCredentials: true,
    transformRequest: [
        function(data, headers) {
            // Get the CSRF token for form requests from a meta tag in _layout
            // Note this is updated on each fetch, getting a fresh value from the node
            // due to static cache lazy-updating this value with Ajax
            headers['X-CSRF-Token'] = document.head.querySelector('meta[name="csrf-token"]').content || '';

            // If this is FormData, no need to serialize
            if (data instanceof FormData) {
                return data;
            }

            return stringify(data);
        },
    ],
});
