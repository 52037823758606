// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/css/style.pcss';

//
// All third-party, non-Vue.js libraries should be loaded here
//

import 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp, defineAsyncComponent } from 'vue';


//
// Components
//

import Field from './components/Field.vue';
import LabeledInput from './components/LabeledInput.vue';
import LabeledDropdown from './components/LabeledDropdown.vue';
import Rot13Email from './components/Rot13Email.vue';
import Navigation from './components/Navigation.vue';
import Product from './components/Product.vue';
import ExitModal from './components/ExitModal.vue';
const Checkout = defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ './components/checkout/Checkout.vue'));
const ServiceArea = defineAsyncComponent(() => import(/* webpackChunkName: "service-area" */ './components/ServiceArea.vue'));

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import {
    Lazy,
    Autoplay,
    Pagination,
    Navigation as SwiperNavigation,
} from 'swiper';

// Collapse transition
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';

import store from './store';

const app = createApp({
    delimiters: [ '${', '}' ],

    data() {
        return {
            swiperModules: [ Lazy, Autoplay, Pagination, SwiperNavigation ],
            isNavigationOpen: false,
            isExitingSite: false,
            exitUrl: null,
        };
    },

    watch: {
        isNavigationOpen(newVal) {
            const { panel } = this.$refs;

            if (newVal) {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });

                disableBodyScroll(panel, {
                    reserveScrollBarGap: true,
                });
            } else {
                enableBodyScroll(panel);
            }
        },
    },

    mounted() {
        const links = document.querySelectorAll('a');
        for (let i = 0; i < links.length; i++) {
            const link = links[i];
            const href = link.getAttribute('href');
            const matches = /^https?:\/\/\w+\.\w+/.exec(href);
            if (!matches) {
                continue;
            }

            const baseUrl = matches[0];
            const matchUrl = location.origin === BASE_URL_GROUP ? BASE_URL_AWNINGS : BASE_URL_GROUP;
            if (baseUrl === matchUrl) {
                link.addEventListener('click', event => {
                    event.preventDefault();

                    this.isExitingSite = true;
                    this.exitUrl = href;
                });
            }
        }
    },

    created() {
        // noinspection JSIgnoredPromiseFromCall
        this.$store.dispatch('cart/loadCart');
    },
});

app.use(store);

app.component('Swiper', Swiper);
app.component('SwiperSlide', SwiperSlide);
app.component('Field', Field);
app.component('LabeledInput', LabeledInput);
app.component('LabeledDropdown', LabeledDropdown);
app.component('Rot13Email', Rot13Email);
app.component('Navigation', Navigation);
app.component('Product', Product);
app.component('ExitModal', ExitModal);
app.component('Checkout', Checkout);
app.component('ServiceArea', ServiceArea);
app.component('CollapseTransition', CollapseTransition);

app.mount('#app');
