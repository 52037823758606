<template>
    <div class="flex items-center fixed inset-0 bg-black/75 z-[999]">
        <div class="w-full">
            <div class="container">
                <div v-click-outside="onClickOutside" class="max-w-lg mx-auto bg-white text-center text-lg px-10 py-16">
                    <div v-if="isAwnings">
                        <logo-awnings class="block w-1/2 mx-auto mb-6" />

                        <p><strong>You are now changing to the Optimo Awnings site.</strong> Optimo Awnings is part of the Optimo Group.</p>

                        <div class="mt-6">
                            <a :href="exitUrl" class="btn btn-secondary">Go to Optimo Awnings</a>
                        </div>
                    </div>

                    <div v-else-if="isGroup">
                        <logo-group class="block w-1/2 mx-auto mb-6" />

                        <p><strong>You are now changing to the Optimo Group site.</strong> Optimo Awnings is part of the Optimo Group.</p>

                        <div class="mt-6">
                            <a :href="exitUrl" class="btn btn-secondary">Go to Optimo Group</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LogoAwnings from '../../img/logo-awnings.svg?component';
    import LogoGroup from '../../img/logo-group.svg?component';
    import vClickOutside from 'click-outside-vue3';

    export default {
        components: {
            LogoAwnings,
            LogoGroup,
        },

        directives: {
            clickOutside: vClickOutside.directive,
        },

        props: {
            exitUrl: {
                type: String,
                required: true,
            },
        },

        emits: [ 'close' ],

        computed: {
            baseUrl() {
                return /^https?:\/\/\w+\.\w+/.exec(this.exitUrl)[0];
            },
            isAwnings() {
                return this.baseUrl === BASE_URL_AWNINGS;
            },
            isGroup() {
                return this.baseUrl === BASE_URL_GROUP;
            },
        },

        methods: {
            onClickOutside() {
                this.$emit('close');
            },
        },
    };
</script>
