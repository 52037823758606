<template>
    <a :href="`mailto:${decodedEmail}`">
        <slot :email="decodedEmail" />
    </a>
</template>

<script>
    export default {
        name: 'Rot13Email',

        props: {
            email: {
                type: String,
                required: true,
            },
        },

        computed: {
            decodedEmail() {
                return this.email.toString().replace(/[a-zA-Z]/g, c => String.fromCharCode((c <= 'Z' ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26));
            },
        },
    };
</script>
