<template>
    <div :class="{ 'fui-floating': floating, [icon]: true, 'fui-has-fa': !!icon }">
        <slot />
    </div>
</template>

<script>
    import field from '../mixins/field';

    export default {
        mixins: [ field ],

        props: {
            icon: {
                type: String,
                default: '',
            },
        },
    };
</script>
