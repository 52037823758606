export default {
    data() {
        return {
            input: null,
            floating: false,
        };
    },

    mounted() {
        this.$el.addEventListener('input', this.onChange);
        this.$el.addEventListener('focus', this.onChange);
        this.$el.addEventListener('blur', this.onChange);

        // Repeater
        this.$el.addEventListener('append', this.onAppend);

        this.onInit();
    },

    beforeDestroy() {
        this.$el.removeEventListener('input', this.onChange);
        this.$el.removeEventListener('focus', this.onChange);
        this.$el.removeEventListener('blur', this.onChange);

        // Repeater
        this.$el.removeEventListener('append', this.onAppend);
    },

    methods: {
        onInit() {
            this.input = this.$el.querySelector('.fui-input, .fui-select');

            if (this.input) {
                this.onChange();
            }
        },
        onChange() {
            const isFocused = document.activeElement === this.input;
            let floating = this.input && (!!this.input.value || !!this.input.placeholder || isFocused);

            if (!floating && this.input && this.input.getAttribute('type') === 'date') {
                // A date field is always floating.
                floating = true;
            }

            if (!floating && this.input && this.input.tagName === 'SELECT') {
                for (const option of this.input.children) {
                    if (option.getAttribute('value') === this.input.value) {
                        floating = !!option.text;
                        break;
                    }
                }
            }

            this.floating = floating;
        },
        onAppend(event) {
            event.preventDefault();

            const { row, form } = event.detail;
            const component = (new Vue({
                parent: this.$parent,
            })).$mount(row);

            const { $el } = component;

            event.detail.row = $el;
            form.form.registerFieldEvents($el);

            return false;
        },
    },
};
