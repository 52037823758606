<template>
    <section>
        <div class="container">
            <div class="md:grid grid-cols-2 gap-12">
                <div>
                    <slot name="gallery" />
                </div>

                <form class="block mt-12 md:mt-0" method="post">
                    <slot name="form" />

                    <h1 class="h1">{{ product.title }}</h1>

                    <div v-if="variant.onSale" class="text-xl font-bold mb-4">
                        <div class="text-primary-500">Sale!</div>

                        <del class="text-gray-500">{{ variant.price }}</del>
                        {{ variant.salePrice }}
                    </div>

                    <div v-else class="text-xl font-bold mb-4">{{ variant.price }}</div>

                    <div class="flex mb-4 -mx-2">
                        <div class="px-2">
                            <label for="qty">Quantity</label>
                            <input
                                id="qty"
                                v-model="quantity"
                                class="fui-input"
                                type="number"
                                min="1"
                                :max="variant.maxQty"
                                name="qty"
                                size="2"
                            />
                        </div>

                        <div class="px-2 flex-1">
                            <label for="variant">Variant</label>
                            <select
                                id="variant"
                                v-model="selectedVariant"
                                name="purchasableId"
                                class="fui-select"
                            >
                                <option v-for="v in variants" :key="v.id" :value="v.id">{{ v.title }}</option>
                            </select>
                        </div>
                    </div>

                    <div v-if="product.shippingCategory === 'dangerousGoods'" class="alert alert-warning">
                        <p>This product is classified as dangerous goods, and is only available for local pickup or <a href="/local-delivery-areas">local delivery to approved areas</a>. Adding this product to your cart will disable postage options.</p>
                    </div>

                    <slot name="web-payments" />

                    <div class="flex items-center mb-8">
                        <button
                            type="submit"
                            class="btn btn-primary"
                            :disabled="!variant.isAvailable"
                        >
                            {{ variant.isAvailable ? 'Add to Cart' : 'Sold Out' }}
                        </button>

                        <a
                            v-if="items.length > 0"
                            href="/shop/cart"
                            class="flex items-center text-secondary-500 ml-4"
                        >
                            <icon-cart class="block w-4 h-4 mr-2" />
                            View cart
                        </a>
                    </div>

                    <slot name="description" />
                </form>
            </div>
        </div>

        <slot
            :variant="variant"
            :variants="variants"
            :selected-variant="selectedVariant"
            :quantity="quantity"
        />
    </section>
</template>

<script>
    import { createNamespacedHelpers } from 'vuex';
    import IconCart from '../../img/icons/icon-cart.svg';
    const { mapState } = createNamespacedHelpers('cart');

    export default {
        components: {
            IconCart,
        },

        props: {
            product: {
                type: Object,
                required: true,
            },
            variants: {
                type: Array,
                required: true,
            },
            initialVariant: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                selectedVariant: this.initialVariant,
                quantity: 1,
            };
        },

        computed: {
            ...mapState({
                items: 'items',
            }),
            variant() {
                return this.variants.find(variant => variant.id === this.selectedVariant);
            },
        },

        watch: {
            selectedVariant() {
                this.updateApplePay();
            },
            quantity() {
                this.updateApplePay();
            },
        },

        mounted() {
            window.payButton?.reload();
        },

        methods: {
            updateApplePay() {
                if (typeof window.payButton !== 'undefined') {
                    window.payButton.items = [ {
                        id: this.selectedVariant,
                        qty: this.quantity,
                    } ];
                }
            },
        },
    };
</script>
