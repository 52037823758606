<template>
    <div class="fui-field fui-label-floating fui-text-input-field" :class="{ 'fui-floating': isFloating, 'fui-focused': focused }">
        <div class="fui-input-container">
            <span v-if="hasDefaultSlot" class="fui-icon">
                <slot />
            </span>

            <label :class="[ labelClass ]" :for="id">{{ label }}&nbsp;<span v-if="required" class="fui-required">*</span></label>

            <input
                :id="id"
                ref="input"
                v-model="localValue"
                :type="type !== 'date' ? type : 'text'"
                :autocomplete="autocomplete"
                :name="name"
                :placeholder="placeholder"
                :required="required"
                :class="[ inputClass ]"
                @change="$emit('change', $event)"
                @keydown="$emit('keydown', $event)"
                @keyup="$emit('keyup', $event)"
                @focus.passive="focus"
                @blur.passive="blur"
            />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            // eslint-disable-next-line vue/require-prop-types
            modelValue: {
                required: false,
                default: '',
            },
            type: {
                type: String,
                default: 'text',
            },
            autocomplete: {
                type: String,
                default: null,
            },
            name: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
            placeholder: {
                type: String,
                default: '',
            },
            required: {
                type: Boolean,
                default: false,
            },
            inputClass: {
                type: [ String, Array, Object ],
                default: 'fui-input',
            },
            labelClass: {
                type: [ String, Array, Object ],
                default: 'fui-label',
            },
        },

        emits: [
            'update:modelValue',
            'change',
            'keydown',
            'keyup',
            'input',
            'focus',
            'blur',
        ],

        data() {
            // Generate a random string made with numbers!
            const randomString = (length = 5) => Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, length);

            return {
                focused: false,
                picker: null,
                id: randomString(5),
            };
        },

        computed: {
            localValue: {
                get() {
                    return this.modelValue;
                },
                set(newValue) {
                    this.$emit('update:modelValue', newValue);
                },
            },
            isFloating() {
                return !!this.localValue || !!this.placeholder || !!this.focused;
            },
            hasDefaultSlot() {
                return !!this.$slots.default;
            },
        },

        methods: {
            focus(event) {
                this.$emit('focus', event);
                this.focused = true;
            },
            blur(event) {
                this.$emit('blur', event);
                this.focused = false;
            },
        },
    };
</script>
