<template>
    <div class="fui-field fui-label-floating fui-select-field" :class="{ 'fui-floating': isFocused }">
        <div class="fui-input-container">
            <span v-if="hasDefaultSlot" class="fui-icon">
                <slot />
            </span>

            <label :class="[ labelClass ]" :for="id">{{ label }}&nbsp;<span v-if="required" class="fui-required">*</span></label>

            <select
                :id="id"
                ref="input"
                v-model="fieldValue"
                :name="name"
                :required="required"
                :class="[ inputClass ]"
                @change="$emit('update:modelValue', fieldValue)"
                @focus="focused = true"
                @blur="focused = false"
            >
                <option v-for="option in options" :key="option.value" :value="option.value">{{ option.label }}</option>
            </select>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            // eslint-disable-next-line vue/require-prop-types
            modelValue: {
                required: false,
                default: '',
            },
            name: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
            options: {
                type: Array,
                required: true,
            },
            required: {
                type: Boolean,
                default: false,
            },
            inputClass: {
                type: [ String, Array, Object ],
                default: 'fui-select',
            },
            labelClass: {
                type: [ String, Array, Object ],
                default: 'fui-label',
            },
        },

        emits: [ 'update:modelValue' ],

        data() {
            // Generate a random string made with numbers!
            const randomString = (length = 5) => Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, length);

            return {
                focused: false,
                picker: null,
                fieldValue: this.modelValue,
                id: randomString(5),
            };
        },

        computed: {
            isFocused() {
                return this.fieldValue || this.focused;
            },
            hasDefaultSlot() {
                return !!this.$slots.default;
            },
        },

        watch: {
            modelValue(newValue) {
                this.fieldValue = newValue;
            },
        },
    };
</script>
